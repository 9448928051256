import { ChakraTheme, extendTheme, theme as baseTheme } from "@chakra-ui/react";

const fonts = {
  ...baseTheme.fonts,
  body: "Inter, " + baseTheme.fonts.body,
  heading: "Inter, " + baseTheme.fonts.heading,
};

const customTheme: Partial<ChakraTheme> = {
  components: {
    Button: {
      defaultProps: {
        size: "sm",
      },
    },
  },
  fonts,
};

export default extendTheme(customTheme);
